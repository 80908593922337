import React from "react";
import Seo from "../components/seo";
import termsPdf from "../pdfs/Evistamp_Legal Notice v1.0.pdf";
const LegalNotice = () => {
  return (
    <>
      <Seo title="Legal Notice" description="Legal Notice for Evistamp" />
      <div>
        <iframe className="iframe-style" src={termsPdf} />
      </div>
    </>
  );
};
export default LegalNotice;
